import { withAuthenticationRequired } from "@auth0/auth0-react";
import { MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";
import fetchDevices from "../functions/fetchDevices";
import fetchData from "../API/Fetch";
import { useSelector } from "react-redux";

function DeviceUpdate() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [device, setDevice] = useState(null);
  const devices = useSelector((state) => state.devices);

  const { register, handleSubmit, control, reset, setValue } = useForm({
    reValidateMode: "onBlur",
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    device_type_id: {
      required: t("common.required", { name: t("common.device_type") }),
    },
    dev_eui: {
      required: t("common.required", { name: t("common.dev_eui") }),
      minLength: t("common.min_length", { length: 8 }),
      pattern: t("common.alphanumeric_only"),
    },

    app_eui: {
      minLength: t("common.min_length", { length: 8 }),
      pattern: t("common.alphanumeric_only"),
    },
    app_key: {
      minLength: t("common.min_length", { length: 16 }),
      pattern: t("common.alphanumeric_only"),
    },
  };

  const onSubmit = async (data) => {
    let { name, device_type_id, app_eui, app_key, dev_eui } = data;

    let jsonData = {
      name: name,
      dev_eui: dev_eui,
      app_eui: app_eui,
      app_key: app_key,
      device_type_id: device_type_id,
    };

    if (device) {
      jsonData.dev_eui = device.dev_eui;
    }

    let response = null;

    if (device) {
      response = await putData(`/devices/${device.dev_eui}`, jsonData);
    } else {
      response = await postData(`/devices/`, jsonData);
    }

    if (response && response.status === 200) {
      updateSnackbarMessage(
        device === null
          ? t("common.added_success", { name: t("common.device") }) +
              " Redirecting to Devices..."
          : t("common.updated_success", { name: t("common.device") }) +
              " Redirecting to Devices..."
      );

      fetchDevices();
      navigate("/devices/");
      return;
    }
    if (response && response.status === 409) {
      updateSnackbarMessage(t("common.dev_eui_exists"));
      return;
    }
    updateSnackbarMessage(
      device === null
        ? t("common.add_error", { name: t("common.device") })
        : t("common.update_error", { name: t("common.device") })
    );
  };

  const generateNewName = (device_type_id) => {
    /*
      cubic_000
      mil_tor_000
      sen_screen_000
      eastron_000
    */
    let name = "";

    switch (device_type_id) {
      case 1:
        name = "sen_screen_";
        break;
      case 2:
        name = "mil_tor_";
        break;
      case 3:
        name = "cubic_";
        break;
      case 4:
        name = "eastron_";
        break;
      default:
        name = "sen_screen_";
        break;
    }

    let count =
      devices.filter((device) => device.device_type_id === device_type_id)
        .length + 1;

    let countString = count.toString().padStart(3, "0");

    name += countString;

    setValue("name", name);
    setValue("device_type_id", device_type_id);
  };

  useEffect(() => {
    store.dispatch({
      type: "pageActions/update",
      payload: [],
    });

    if (!params.id) {
      return;
    }

    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.update") + " " + t("common.device"),
    });

    fetchData(`/devices/${params.id}`).then((data) => {
      setDevice(data);
    });
  }, [params.id, t]);

  useEffect(() => {
    if (!device) {
      return;
    }

    const { name, device_type_id, dev_eui } = device;

    reset({
      name: name,
      device_type_id: device_type_id,
      dev_eui: dev_eui,
    });
  }, [device, reset]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={device === null ? 6 : 4}>
          <Controller
            name="device_type_id"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="device_type_id"
                  label={t("common.device_type")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.device_type_id[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("device_type_id")}
                  select
                  value={field.value ? field.value : 0}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    generateNewName(e.target.value);
                  }}
                >
                  <MenuItem key={"device_type_id"} value={1}>
                    {t("common.sen_screen")}
                  </MenuItem>
                  <MenuItem key={"device_type_id"} value={2}>
                    {t("common.milesight_ct101")}
                  </MenuItem>
                  <MenuItem key={"device_type_id"} value={3}>
                    {t("common.quandify_cubic_meter")}
                  </MenuItem>
                  <MenuItem key={"device_type_id"} value={4}>
                    {t("common.EastronMid")}
                  </MenuItem>
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={device === null ? 6 : 4}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("name")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="dev_eui"
            disabled={device ? true : false}
            control={control}
            rules={{
              required: true,
              minLength: 8,
              pattern: /^[a-zA-Z0-9]*$/,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="dev_eui"
                  label={t("common.dev_eui")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.dev_eui[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("dev_eui")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        {
          //only show these fields if its an add
        }
        {device === null && (
          <>
            <Grid item xs={4}>
              <Controller
                name="app_eui"
                control={control}
                rules={{
                  minLength: 8,
                  pattern: /^[a-zA-Z0-9]*$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="app_eui"
                      label={t("common.app_eui")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.name[error.type] : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("app_eui")}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="app_key"
                control={control}
                rules={{
                  minLength: 16,
                  //mix of leter and numbers only
                  pattern: /^[a-zA-Z0-9]*$/,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="app_key"
                      label={t("common.app_key")}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.name[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...register("app_key")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              {device === null ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(DeviceUpdate);
