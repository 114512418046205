import { Button } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DialogActions, DialogContentText } from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../../store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import deleteData from "../../API/Delete";

export default function DeleteDialog(props) {
  const deleteDialog = useSelector((state) => state.deleteDialog);
  const { t } = useTranslation("common");

  const brands = useSelector((state) => state.brands);
  const residences = useSelector((state) => state.residences);
  const devices = useSelector((state) => state.devices);
  const establishments = useSelector((state) => state.establishments);

  const [records, setRecords] = useState([]);

  const onDelete = () => {
    deleteRecords(deleteDialog.type, deleteDialog.records);
    onClose();

    store.dispatch({
      type: "snackbar/update",
      payload: {
        open: true,
        message: t("common.delete_success", {
          name: t("common." + deleteDialog.type),
        }),
        severity: "success",
      },
    });
  };

  const deleteRecords = (type, records) => {
    let typeToURL = "";

    if (type === "residences") {
      typeToURL = "residences";
    } else if (type === "brands") {
      typeToURL = "brands";
    } else if (type === "devices") {
      typeToURL = "devices";
    } else if (type === "establishments") {
      typeToURL = "establishments";
    }

    records.forEach((record) => {
      deleteData("/" + typeToURL + "/" + record);
    });

    //remove the records from the store
    store.dispatch({
      type: typeToURL + "/delete",
      payload: records,
    });
  };

  useEffect(() => {
    if (!deleteDialog || !deleteDialog.records) return;
    if (!brands || !residences || !devices || !establishments) return;

    let recordsFound = [];
    if (deleteDialog && deleteDialog.records) {
      //find the type of the records
      if (deleteDialog.type === "brands") {
        let foundBrands = brands.filter((brand) => {
          return deleteDialog.records.includes(brand.id);
        });

        let brandsWithCount = foundBrands.map((brand) => {
          let count = establishments.filter(
            (establishment) => establishment.brand_id === brand.id
          ).length;

          let type = t("common.establishments");
          return { ...brand, count: count, type: type };
        });

        recordsFound = brandsWithCount;
      } else if (deleteDialog.type === "establishments") {
        let foundEstablishments = establishments.filter((establishment) => {
          return deleteDialog.records.includes(establishment.id);
        });

        //add a count of residences associated with the establishment
        let establishmentsWithCount = foundEstablishments.map(
          (establishment) => {
            let count = residences.filter(
              (residence) => residence.establishment_id === establishment.id
            ).length;

            let type = t("common.residences");
            return { ...establishment, count: count, type: type };
          }
        );

        recordsFound = establishmentsWithCount;
      } else if (deleteDialog.type === "residences") {
        let foundResidences = residences.filter((residence) => {
          return deleteDialog.records.includes(residence.id);
        });

        recordsFound = foundResidences;
      } else if (deleteDialog.type === "devices") {
        let foundDevices = devices.filter((device) => {
          return deleteDialog.records.includes(device.dev_eui);
        });

        recordsFound = foundDevices;
      }

      setRecords(recordsFound);
    }
  }, [deleteDialog, t, brands, residences, devices, establishments]);

  const onClose = () => {
    store.dispatch({
      type: "deleteDialog/update",
      payload: { open: false, type: "" },
    });
  };

  return (
    <Dialog
      open={deleteDialog && deleteDialog.open ? true : false}
      onClose={onClose}
    >
      <DialogTitle>
        Delete {deleteDialog && deleteDialog.type ? deleteDialog.type : ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("common.delete_dialog_message")}
        </DialogContentText>
        <DialogContentText sx={{ mt: 2 }}>
          {records.map((record) => (
            <div key={record.id}>
              {record.name}{" "}
              {record.count !== undefined
                ? "( " + record.count + " " + record.type + " )"
                : ""}
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" onClick={onDelete} color="error">
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
