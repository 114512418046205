import { useSelector } from "react-redux";
import DataTableBase from "./tables/DataTableBase";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Chip } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Icon } from "@mui/material";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bolt, WaterDrop } from "@mui/icons-material";

export default function ResidenceDashboard() {
  const selectedEstablishment = useSelector(
    (state) => state.selectedEstablishment
  );

  const navigate = useNavigate();

  const { t } = useTranslation("common");

  let green = "#6bc769";
  let yellow = "#fedd37";
  let red = "#fe3e26";
  let blue = "#bdbdbd";

  const residences = useSelector((state) => state.residences);
  const screens = useSelector((state) => state.screens);
  const establishments = useSelector((state) => state.establishments);
  const packs = useSelector((state) => state.packs);
  const userDB = useSelector((state) => state.userDB);

  const selectedResidences =
    selectedEstablishment !== "0"
      ? residences.filter(
          (residence) =>
            parseInt(residence.establishment_id) ===
            parseInt(selectedEstablishment)
        )
      : residences;

  let columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <Typography variant="h6">{value}</Typography>;
        },
      },
    },
    {
      name: "smiley_color",
      label: t("common.status"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let residence = selectedResidences.find(
            (residence) =>
              parseInt(residence.id) === parseInt(tableMeta.rowData[0])
          );

          if (!residence) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let pack = packs.find(
            (pack) => parseInt(pack.id) === parseInt(residence.pack_id)
          );

          if (!pack) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let screen = screens.find(
            (screen) => parseInt(screen.pack_id) === parseInt(pack.id)
          );

          //if smiley is green, :D else if smiley is yellow, :) else :(

          if (!screen) {
            return (
              <Icon
                sx={{
                  backgroundColor: green,
                  color: "black",
                  fontSize: "2em",
                  borderRadius: "50%",
                  p: 1,
                }}
              >
                {"sentiment_satisfied_alt"}
              </Icon>
            );
          }

          //mui icon
          return (
            <Icon
              sx={{
                backgroundColor:
                  screen.smiley_color === "green"
                    ? green
                    : screen.smiley_color === "yellow"
                    ? yellow
                    : red,
                color: "black",
                fontSize: "2em",
                borderRadius: "50%",
                p: 1,
              }}
            >
              {screen.smiley_color === "green"
                ? "sentiment_satisfied_alt"
                : screen.smiley_color === "yellow"
                ? "sentiment_dissatisfied"
                : "sentiment_very_dissatisfied"}
            </Icon>
          );
        },
      },
    },
    {
      name: "water_usage",
      label: t("common.water_usage"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let residence = selectedResidences.find(
            (residence) =>
              parseInt(residence.id) === parseInt(tableMeta.rowData[0])
          );

          if (!residence) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let pack = packs.find(
            (pack) => parseInt(pack.id) === parseInt(residence.pack_id)
          );

          if (!pack) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let screen = screens.find(
            (screen) => parseInt(screen.pack_id) === parseInt(pack.id)
          );

          let establishment = establishments.find(
            (establishment) =>
              parseInt(establishment.id) ===
              parseInt(residence.establishment_id)
          );

          if (!establishment) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          if (!screen) {
            return (
              <Stack direction="row" spacing={1}>
                <Chip
                  icon={<WaterDrop color="black" />}
                  label={"0L"}
                  sx={{
                    backgroundColor: green,
                    fontSize: "1.5em",
                    color: "black",
                  }}
                />
                <Chip
                  label={"0€"}
                  sx={{
                    backgroundColor: blue,
                    fontSize: "1.5em",
                    color: "black",
                  }}
                />
              </Stack>
            );
          }

          let cost = (screen.water_liter * establishment.cost_per_liter) / 1000;

          //round to 2 decimal places
          cost = Math.round(cost * 100) / 100;

          return (
            <Stack direction="row" spacing={1}>
              <Chip
                icon={<WaterDrop color="black" />}
                label={screen.water_liter + "L"}
                sx={{
                  backgroundColor:
                    screen.water_color === "green"
                      ? green
                      : screen.water_color === "yellow"
                      ? yellow
                      : red,
                  fontSize: "1.5em",
                  color: "black",
                }}
              />
              <Chip
                label={cost + "€"}
                sx={{
                  backgroundColor: blue,
                  fontSize: "1.5em",
                  color: "black",
                }}
              />
            </Stack>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;

            let packA = packs.find((pack) => pack.water_deveui === val1);
            let packB = packs.find((pack) => pack.water_deveui === val2);

            //if either are undefined use 0 for value
            if (!packA) {
              packA = 0;
            }

            if (!packB) {
              packB = 0;
            }

            let screenA = 0;
            let screenB = 0;

            if (packA !== 0) {
              screenA = screens.find(
                (screen) => screen.dev_eui === packA.screen_deveui
              );

              if (!screenA) {
                screenA = 0;
              } else {
                screenA = screenA.water_liter;
              }
            }

            if (packB !== 0) {
              screenB = screens.find(
                (screen) => screen.dev_eui === packB.screen_deveui
              );

              if (!screenB) {
                screenB = 0;
              } else {
                screenB = screenB.water_liter;
              }
            }

            return screenA - screenB * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "elec_usage",
      label: t("common.electricity_usage"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          let residence = selectedResidences.find(
            (residence) =>
              parseInt(residence.id) === parseInt(tableMeta.rowData[0])
          );

          if (!residence) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let establishment = establishments.find(
            (establishment) =>
              parseInt(establishment.id) ===
              parseInt(residence.establishment_id)
          );

          if (!establishment) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let pack = packs.find(
            (pack) => parseInt(pack.id) === parseInt(residence.pack_id)
          );

          if (!pack) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          let screen = screens.find(
            (screen) => parseInt(screen.pack_id) === parseInt(pack.id)
          );

          if (!screen) {
            return (
              <Stack direction="row" spacing={1}>
                <Chip
                  icon={<Bolt color="black" />}
                  label={"0kWh"}
                  sx={{
                    backgroundColor: green,
                    fontSize: "1.5em",
                    color: "black",
                  }}
                />
                <Chip
                  label={"0€"}
                  sx={{
                    backgroundColor: blue,
                    fontSize: "1.5em",
                    color: "black",
                  }}
                />
              </Stack>
            );
          }

          let cost = screen.electricity_kwh * establishment.cost_per_kwh;

          //round to 2 decimal places
          cost = Math.round(cost * 100) / 100;

          return (
            <Stack direction="row" spacing={1}>
              <Chip
                icon={<Bolt color="black" />}
                label={screen.electricity_kwh + "kWh"}
                sx={{
                  backgroundColor:
                    screen.electricity_color === "green"
                      ? green
                      : screen.electricity_color === "yellow"
                      ? yellow
                      : red,
                  fontSize: "1.5em",
                  color: "black",
                }}
              />
              <Chip
                label={cost + "€"}
                sx={{
                  backgroundColor: blue,
                  fontSize: "1.5em",
                  color: "black",
                }}
              />
            </Stack>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;

            let packA = packs.find((pack) => pack.elec_deveui === val1);
            let packB = packs.find((pack) => pack.elec_deveui === val2);

            //if either are undefined use 0 for value
            if (!packA) {
              packA = 1000;
            }

            if (!packB) {
              packB = 1000;
            }

            let screenA = 1000;
            let screenB = 1000;

            if (packA !== 1000) {
              screenA = screens.find(
                (screen) => screen.dev_eui === packA.screen_deveui
              );

              if (!screenA) {
                screenA = 1000;
              } else {
                screenA = screenA.electricity_kwh;
              }
            }

            if (packB !== 1000) {
              screenB = screens.find(
                (screen) => screen.dev_eui === packB.screen_deveui
              );

              if (!screenB) {
                screenB = 1000;
              } else {
                screenB = screenB.electricity_kwh;
              }
            }

            //sort by toal usage
            return screenA - screenB * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "current_occupancy",
      label: t("common.current_occupancy"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          //show x of y using capacity and current_occupancy
          let residence = selectedResidences.find(
            (residence) =>
              parseInt(residence.id) === parseInt(tableMeta.rowData[0])
          );

          if (!residence) {
            return <Chip label="" sx={{ backgroundColor: green }} />;
          }

          return (
            <Typography variant="h6">
              {residence.current_occupation} of {residence.capacity}
            </Typography>
          );
        },
      },
    },
    {
      name: "Options",
      label: t("common.options"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              variant="contained"
              color={"success"}
              onClick={() => {
                //redirect to residence details
                navigate("/residences/" + tableMeta.rowData[0]);
              }}
            >
              {t("common.edit")}
            </Button>
          );
        },
      },
    },
  ];
  const tableActions = [];

  if (userDB) {
    //check userDB.admin, if false, remove the Options column
    if (!userDB.admin) {
      columns = columns.filter((column) => column.name !== "Options");
    }
  }

  return (
    <Grid item xs={12}>
      <DataTableBase
        columns={columns}
        data={selectedResidences}
        title={t("common.residences")}
        id="residence-list"
        name="residence"
        pageSize={10}
        actions={tableActions}
        sortOrder="name"
        onRowClick={(rowData) => {
          navigate("/view/" + rowData[0]);
        }}
      />
    </Grid>
  );
}
