import { withAuthenticationRequired } from "@auth0/auth0-react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";
import fetchBrands from "../functions/fetchBrands";
import fetchData from "../API/Fetch";

function BrandUpdate() {
  const users = useSelector((state) => state.users);

  const [brand, setBrand] = useState(null);

  const navigate = useNavigate();

  const params = useParams();
  const { t } = useTranslation("common");

  const { register, handleSubmit, control, reset } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      name: "",
      owner_id: 2,
      yellow_water_limit_per_person: "150",
      yellow_elec_limit_per_person: "8",
      red_water_limit_per_person: "200",
      red_elec_limit_per_person: "10",
      screen_brightness: "50",
      uplink_rate_seconds: "300",
      screen_on_start: "9",
      screen_on_end: "22",
    },
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    owner: {
      required: t("common.required", { name: t("common.owner") }),
    },

    yellow_water_limit_per_person: {
      number: t("common.number_only"),
    },
    yellow_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    red_water_limit_per_person: {
      number: t("common.number_only"),
    },
    red_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    screen_brightness: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 100",
    },
    uplink_rate_seconds: {
      number: t("common.number_only"),
    },
    screen_on_start: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
    screen_on_end: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
  };

  const onSubmit = async (data) => {
    let {
      name,
      yellow_water_limit_per_person,
      yellow_elec_limit_per_person,
      red_water_limit_per_person,
      red_elec_limit_per_person,
      screen_brightness,
      uplink_rate_seconds,
      screen_on_start,
      screen_on_end,
      owner_id,
    } = data;

    let jsonData = {
      name: name,
      owner_id: owner_id,
      yellow_water_limit_per_person: yellow_water_limit_per_person,
      yellow_elec_limit_per_person: yellow_elec_limit_per_person,
      red_water_limit_per_person: red_water_limit_per_person,
      red_elec_limit_per_person: red_elec_limit_per_person,
      screen_brightness: screen_brightness,
      uplink_rate_seconds: uplink_rate_seconds,
      screen_on_start: screen_on_start,
      screen_on_end: screen_on_end,
    };

    let response = null;

    if (brand) {
      response = await putData(`/brands/${brand.id}`, jsonData);
    } else {
      response = await postData(`/brands/`, jsonData);
    }

    if (response.status === 200) {
      updateSnackbarMessage(
        brand === null
          ? t("common.added_success", { name: t("common.brand") }) +
              " Redirecting to Brands..."
          : t("common.updated_success", { name: t("common.brand") }) +
              " Redirecting to Brands..."
      );

      fetchBrands();
      navigate("/brands/");
    } else {
      updateSnackbarMessage(
        brand === null
          ? t("common.add_error", { name: t("common.brand") })
          : t("common.update_error", { name: t("common.brand") })
      );
    }
  };

  useEffect(() => {
    if (params.id) {
      fetchData(`/brands/${params.id}`).then((data) => {
        setBrand(data);
      });

      store.dispatch({
        type: "pageTitle/update",
        payload: t("common.update") + " " + t("common.brand"),
      });
    } else {
      store.dispatch({
        type: "pageTitle/update",
        payload: t("common.add_new", { name: t("common.brand") }),
      });
    }
  }, [params.id, t]);

  useEffect(() => {
    store.dispatch({
      type: "pageActions/update",
      payload: [],
    });

    if (!brand) {
      return;
    }

    const {
      name,
      owner_id,
      yellow_water_limit_per_person,
      yellow_elec_limit_per_person,
      red_water_limit_per_person,
      red_elec_limit_per_person,
      screen_brightness,
      uplink_rate_seconds,
      screen_on_start,
      screen_on_end,
    } = brand;

    reset({
      name: name,
      owner_id: owner_id,
      yellow_water_limit_per_person: yellow_water_limit_per_person,
      yellow_elec_limit_per_person: yellow_elec_limit_per_person,
      red_water_limit_per_person: red_water_limit_per_person,
      red_elec_limit_per_person: red_elec_limit_per_person,
      screen_brightness: screen_brightness,
      uplink_rate_seconds: uplink_rate_seconds,
      screen_on_start: screen_on_start,
      screen_on_end: screen_on_end,
    });
  }, [brand, reset]);

  const [showLimits, setShowLimits] = useState(false);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("name")}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="owner_id"
            control={control}
            defaultValue={users.length > 0 ? users[0].id : ""}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                }}
              >
                <TextField
                  id="owner_id"
                  label={t("common.owner")}
                  fullWidth
                  select
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  error={error !== undefined}
                  helperText={error ? validationLabels.owner[error.type] : ""}
                  variant="outlined"
                  {...register("owner_id")}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.email}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        {showLimits && (
          <>
            <Grid item xs={3}>
              <Controller
                name="yellow_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_water_limit_per_person"
                      label={t("common.yellow_water_limit_per_person")}
                      type={"number"}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="yellow_elec_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_elec_limit_per_person"
                      label={t("common.yellow_elec_limit_per_person")}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_water_limit_per_person"
                      label={t("common.red_water_limit_per_person")}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      type={"number"}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_elec_limit_per_person"
                control={control}
                //rules, number only
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_elec_limit_per_person"
                      label={t("common.red_elec_limit_per_person")}
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_brightness"
                control={control}
                //rules number only, 0 - 100
                rules={{
                  number: true,
                  min: 0,
                  max: 100,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_brightness"
                      label={t("common.screen_brightness")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_brightness[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_brightness")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="uplink_rate_seconds"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="uplink_rate_seconds"
                      label={t("common.uplink_rate_seconds")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type={"number"}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              {t("common.seconds")}
                            </InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.uplink_rate_seconds[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("uplink_rate_seconds")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_start"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_start"
                      label={t("common.screen_on_start")}
                      type={"number"}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_on_start[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_start")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_end"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_end"
                      label={t("common.screen_on_end")}
                      fullWidth
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.screen_on_end[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_end")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setShowLimits(!showLimits)}
            >
              {showLimits ? t("common.hide_limits") : t("common.show_limits")}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              {brand === null ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(BrandUpdate);
