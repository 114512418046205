export const CONFIG = {
  title: "EcoludiK",
  version: "v1.0.0",
  defaultLanguage: "fr",
  defaultTheme: "dark",
  defaultLocale: "en",
  defaultRefreshRate: 15,
  primaryColour: "#70a87b",
  primaryColourRGB: {
    r: 8,
    g: 79,
    b: 106,
  },
  secondaryColour: "#528396",
  Auth0Domain: "ecoludik.eu.auth0.com",
  Auth0ClientId: "NPHAKHZskbRgl5aaDZruduO7OT81uajb",
  Auth0Audience: "https://api.ecoludik.fr/",
  API_URL: "https://api.ecoludik.444.ovh",
  //API_URL: "http://localhost:8000",
  rowsPerPage: 100,
};
