import { Box, Card, Stack, Typography } from "@mui/material";

export default function UsageMetric(props) {
  const { title, value, unit, icon, color, secondaryValue } = props;

  return (
    <Card sx={{ display: "flex", flexFlow: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Box>
          <Typography>{title}</Typography>
        </Box>
      </Box>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 2,
          pt: 0,
          //full height of the card
          flex: "1 1 auto",
        }}
      >
        {icon}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                alignSelf: "right",
                fontSize: "60px",
                mr: 1,
                lineHeight: "60px",
              }}
              color={color}
            >
              {value}
            </Typography>
            <Typography color={color}>{unit}</Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "1.5rem",
              ml: 1.2,
              textAlign: "right",
            }}
          >
            {secondaryValue}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
