import {
  CalendarViewDay,
  CalendarViewMonth,
  CalendarViewWeek,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function DateSwitcher(props) {
  const {
    dateType,
    changePeriod,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = props;

  const { t } = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Button
        variant={dateType === "day" ? "contained" : "outlined"}
        onClick={() => changePeriod("day")}
        startIcon={<CalendarViewDay />}
        color="success"
      >
        {t("common.day")}
      </Button>
      <Button
        variant={dateType === "week" ? "contained" : "outlined"}
        onClick={() => changePeriod("week")}
        startIcon={<CalendarViewWeek />}
        color="success"
      >
        {t("common.week")}
      </Button>
      <Button
        variant={dateType === "month" ? "contained" : "outlined"}
        onClick={() => changePeriod("month")}
        startIcon={<CalendarViewMonth />}
        color="success"
      >
        {t("common.month")}
      </Button>
      <Button
        color="success"
        variant="outlined"
        sx={{ pl: 0, pr: 0, minWidth: "20px" }}
        onClick={() => {
          setStartDate(moment(startDate).subtract(1, dateType));
          setEndDate(moment(endDate).subtract(1, dateType));
        }}
      >
        <ChevronLeft />
      </Button>
      <Button
        startIcon={<CalendarViewDay />}
        color="success"
        variant="outlined"
      >
        {moment(startDate).format("DD/MM/YYYY")} -{" "}
        {moment(endDate).format("DD/MM/YYYY")}
      </Button>
      <Button
        color="success"
        variant="outlined"
        sx={{ pl: 0, pr: 0, minWidth: "20px" }}
        onClick={() => {
          const newStartDate = moment(startDate).add(1, dateType);
          const newEndDate = moment(endDate).add(1, dateType);
          if (dateType === "day" && newStartDate.isAfter(moment())) {
            return;
          }
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }}
      >
        <ChevronRight />
      </Button>
    </Box>
  );
}
